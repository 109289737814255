import React, {useEffect, useRef, useState} from "react";
import {useHistory, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {_getUser} from "../../store/user/service";
import {_getCountry, _getLang, _hideSpinner, _setClaimsToken, _showSpinner} from "../../store/system/service";
import {_validateForm} from "../../utils/utility";
import {_searchClaim} from "../../store/claims/service";
import useClaimsStyles from "./claims-style";
import useConfig from "../qnect-ui/qnect-config";
import SearchIcon from "../../assets/Search.svg";
import FieldWrapper from "../custom/field-wrapper";
import QnectTextField from "../qnect-ui/qnect-text-field";
import QnectButton from "../qnect-ui/qnect-button";
import * as TagManager from "react-gtm-module";
import QnectError from "../qnect-ui/qnect-error";
import QnectSelectField from "../qnect-ui/qnect-select-field";


const Claims = props => {
    const cfg = useConfig(props.lang);
    const history = useHistory();
    const classes = useClaimsStyles(props);
    const ref = useRef(null);
    const formId = 'claimsStatusCheckForm';
    const [claimNumber, setClaimNumber] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [entity, setEntity] = useState(props.user?.country);

    useEffect(() => {
        setErrorMessage([]);
    },[]);

    const checkClaimStatus = () => {
        if (_validateForm(formId)) {
            props.showSpinner();
            setErrorMessage([]);
            props.searchClaim(claimNumber.trim(), entity).then(resp => {
                if (resp && (Object.keys(resp).length > 0)) {
                    props.setClaimsToken(resp.token);
                    history.push('/claimsDetails');
                } else {
                    setErrorMessage(cfg('errorMessage.claimNotFound'));
                }
                props.hideSpinner();
            }).catch(error => {
                setErrorMessage(error.message);
                props.hideSpinner();
            });
        }
        TagManager.dataLayer({
            dataLayer: {
                event: 'Search',
                claimNumber: claimNumber
            }
        });
    }

    return (
        <div style={{overflowY: "auto !important"}}>
            <div className={classes.claims_rectangle} style={props.country === 'HKG' ? {height: "550px"} : {height: "450px"}}>
                <img src={SearchIcon} alt="SearchIcon" className={classes.searchicon}/>
                <div className={classes.claims_title}>
                    <h2>{cfg('claimStatusChecking.title')}</h2>
                </div>
                <div className={classes.claims_subtitle}>
                    <h2>{cfg('claimStatusChecking.label')}</h2>
                </div>
                <div className={classes.Mform} ref={ref}>
                    <div className={classes.member_input}>
                        <FieldWrapper>
                            <QnectTextField
                                field="claimNumber"
                                value={claimNumber}
                                onChange={(evt) => {
                                    setErrorMessage([]);
                                    setClaimNumber(evt.target.value);}}
                                formId={formId}
                            />
                        </FieldWrapper>
                    </div>
                    { props.country === 'HKG'  &&
                         <div className={classes.member_input}>
                            <FieldWrapper >
                            <QnectSelectField
                                formId={formId}
                                field="entity"
                                value={entity}
                                onChange={(val) => {
                                    setErrorMessage([]);
                                    setEntity(val);
                                }}
                             />
                             </FieldWrapper>
                             <div>
                                 <small>{cfg('claimStatusChecking.remark')}</small>
                             </div>
                        </div>
                    }
                    {errorMessage && (
                        <div className={classes.errorMessage}>
                            <QnectError errorTitle={"Error"} errorMessage={errorMessage} />
                        </div>
                    )}
                    <div className={classes.member_input}>
                        <FieldWrapper>
                            <QnectButton
                                field="search"
                                onClick={() => checkClaimStatus()}                              
                            />
                        </FieldWrapper>
                    </div>
                </div>
            </div>
        </div>
    );
};


const mapStateToProps = (state) => {
    return {
        country: _getCountry(state),
        user: _getUser(state),
        lang: _getLang(state)
    };
}

const mapDispatchToProps = {
    searchClaim: _searchClaim,
    showSpinner: _showSpinner,
    hideSpinner: _hideSpinner,
    setClaimsToken: _setClaimsToken
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Claims));
