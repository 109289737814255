import {property} from "lodash";
import * as type from "./types";
import axios from "axios";
import {_getCpToken, _getClaimsToken, _getDocumentToken} from "../system/service";
import {_sendLog} from "../../utils/utility";
import moment from "moment";

const authCpHeader = (state) => {
    const cpToken = _getCpToken(state);
    if (cpToken) {
        return { 'Authorization': 'Bearer ' + cpToken };
    } else {
        return {};
    }
}

export const _storeClaim = (claim) => {
  return (dispatch) => {
    dispatch({
      type: type.SET_CLAIM,
      data: claim
    })
  };
}

export const _resetClaim = () => {
  return (dispatch) => {
    dispatch({
      type: type.RESET_CLAIM
    })
  };
}

export const _searchClaim = (claimNumber, entity) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const requestBody = {
              claimNumber: claimNumber,
              entity: entity,
            };
            let config = {
              headers: authCpHeader(getState()),
              timeout: 60000
            }

            axios.post(process.env.REACT_APP_SEARCH_CLAIM, requestBody, config)
                .then((response) => {
                    let claimData = formatClaimDetailsData(response.data);
                    dispatch({
                        type: type.SET_CLAIM,
                        data: claimData
                    });
                    resolve(claimData);
                })
                .catch((error) => {
                    _sendLog(['Supplier Portal - Claims Service - _searchClaim', JSON.stringify(error)],
                        'error');
                    reject(error);
                })
        })
    }
}

const formatClaimDetailsData = (data) => {
    let formattedClaimData = {};
    
    if (data.status === 'success' && data.claimDetails) {
        let rawClaimData = data.claimDetails;

        formattedClaimData.entity = getClaimEntity(rawClaimData.entity);
        formattedClaimData.productType = rawClaimData.productType;
        formattedClaimData.productDescription = rawClaimData.productDescription;
        formattedClaimData.claimNumber = rawClaimData.claimNumber;
        formattedClaimData.qnectStatus = rawClaimData.status;
        formattedClaimData.qnectStatusLong = getClaimStatus(rawClaimData.status);
        formattedClaimData.clientName = rawClaimData.clientName;
        formattedClaimData.dateOfLoss = moment(rawClaimData.dateOfLoss).format("DD MMMM YYYY");
        formattedClaimData.lastUpdate = moment(rawClaimData.lastUpdate).format("DD MMMM YYYY");
        formattedClaimData.token = data.token;
        formattedClaimData.hasClaimUnit = true;
        formattedClaimData.isProviderAllowedInvoiceSubmission = data.isProviderAllowedInvoiceSubmission;

        if (rawClaimData.claimNotificationNumber) {
            if (formattedClaimData.claimNumber) {
                formattedClaimData.claimNumber += " (" + rawClaimData.claimNotificationNumber + ")";
            } else {
                formattedClaimData.claimNumber = rawClaimData.claimNotificationNumber;
                formattedClaimData.hasClaimUnit = false;
            }
        }
    }

    return formattedClaimData;
}

export const getClaimStatus = (status) => {
    let statDesc = '';
    let stats = StatusRemarks.find(statusRemark => (status !== null || status !== '') &&
      statusRemark.value.toLowerCase() === status.toLowerCase());
    if (stats) statDesc = stats.description;

    return statDesc;
}

export const getClaimEntity = (entity) => {
   return entity?.toUpperCase() === 'HGI' ? 'HKG2' : entity;
}

export const _loadDocument = (dDocName) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let config = {
                headers: authCpHeader(getState())
            }
            const requestBody = {
                dDocName: dDocName,
                documentToken: _getDocumentToken(getState())
            };

            axios.post(process.env.REACT_APP_DOWNLOAD_DOCUMENT, requestBody, config)
                .then(resp => {
                    resolve(resp.data);
                })
                .catch((error) => {
                _sendLog(['Claim Supplier Portal - Claims Service - _loadDocument', JSON.stringify(error)],
                    'error');
                reject(error);
            });
        })
    }
}

export const _getClaimDocs = (docType, productType, invoiceNumber) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let config = {
                headers: authCpHeader(getState()),
                params: {
                    type: docType,
                    productType: productType,
                    claim: _getClaimsToken(getState()),
                    invoiceNumber: invoiceNumber
                },
                timeout: 180000
            };

            axios.get(process.env.REACT_APP_RETRIEVE_DOCUMENTS, config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    _sendLog(['Supplier Portal React - Claims Service - _getClaimDocs', JSON.stringify(error)],
                        'error');
                    reject(error);
                })
        })
    }
}


export const StatusRemarks =
  [
    {
        value:  "Adjuster Assessment in Progress",
        status:  "claimStatus.adjusterAssessment",
        description: ""
    },
    {
        value:  "Fire Services Department investigation in progress",
        status:  "claimStatus.fireInvestigation",
        description: "claimStatus.fireInvestigationDesc"
    },
    {
        value:  "Police investigation in progress",
        status:  "claimStatus.policeInvestigation",
        description: "claimStatus.policeInvestigationDesc"
    },
    {
        value:  "Consultant investigation in progress",
        status:  "claimStatus.consultantInvestigation",
        description: ""
    },
    {
        value:  "Death Inquest in progress",
        status:  "claimStatus.deathInquest",
        description: "claimStatus.deathInquestDesc"
    },
    {
        value:  "Pending for the third party claim",
        status:  "claimStatus.pendingThirdParty",
        description: "claimStatus.pendingThirdPartyDesc"
    },
    {
        value:  "Await the third party response",
        status:  "claimStatus.awaitThirdParty",
        description: "claimStatus.awaitThirdPartyDesc"
    },
    {
        value:  "Await policy excess from the Insured",
        status:  "claimStatus.awaitPolicyExcess",
        description: "claimStatus.awaitPolicyExcessDesc"
    },
    {
        value:  "Investigation in progress",
        status:  "claimStatus.investigation",
        description: ""
    },
    {
        value:  "Survey in progress",
        status:  "claimStatus.survey",
        description: ""
    },
    {
        value:  "Litigation in progress",
        status:  "claimStatus.litigation",
        description: ""
    },
    {
        value:  "The third party claim is declined",
        status:  "claimStatus.declinedThirdParty",
        description: "claimStatus.declinedThirdPartyDesc"
    },
    {
        value:  "Negotiating with the third party",
        status:  "claimStatus.negotThirdParty",
        description: "claimStatus.negotThirdPartyDesc"
    },
    {
        value:  "Labour Department Investigation in progress",
        status:  "claimStatus.labourDepartment",
        description: "claimStatus.labourDepartmentDesc"
    },
    {
        value:  "Medical Clearance to be scheduled",
        status:  "claimStatus.medClearanceScheduled",
        description: ""
    },
    {
        value:  "Medical Clearance to be conducted",
        status:  "claimStatus.medClearanceConducted",
        description: ""
    },
    {
        value: "Medical Assessment Board to be scheduled",
        status: "claimStatus.medAssessmentScheduled",
        description: ""
    },
    {
        value: "Medical Assessment Board to be conducted",
        status: "claimStatus.medAssessmentConducted",
        description: ""
    },
    {
        value: "Claim Received",
        status: "claimStatus.claimReceived",
        description: "claimStatus.claimReceivedDesc"
    },
    {
        value: "Notification received",
        status: "claimStatus.notifReceived",
        description: "claimStatus.notifReceivedDesc"
    },
    {
        value: "Additional Documents Request via Email / Letter",
        status: "claimStatus.addtlDocuments",
        description: "claimStatus.addtlDocumentsDesc"
    },
    {
        value: "In assessment",
        status: "claimStatus.assessment",
        description: "claimStatus.assessmentDesc"
    },
    {
        value: "Payment Processed",
        status: "claimStatus.paid",
        description: "claimStatus.paidDesc"
    },
    {
        value: "Claim Closed",
        status: "claimStatus.closed",
        description: "claimStatus.closedDesc"
    },
    {
        value: "Linked claim file status",
        status: "claimStatus.closed",
        description: "claimStatus.closedDesc"
    },
    {
        value: "Received prosecution summons from the insured",
        status: "claimStatus.summon",
        description: ""
    }
  ];

export const _getClaim = property ( 'claim' );
